<template>
  <div id="login-wrap">
    <div class="login-box">
      <div class="step-1">
        <img class="logo" src="../../assets/logo-2.png">
        <h2 class="name">
          小工具平台
        </h2>
        <a-form
          :form="form"
          @submit="handleSubmit"
        >
          <a-form-item
            class="username"
          >
            <a-input
              v-decorator="[
          'username',
          {rules: [{ required: true, message: '请输入用户名' }]}
        ]"
              placeholder="用户名"
            />
          </a-form-item>
          <a-form-item
            class="password"
          >
            <a-input
              v-decorator="[
          'userpwd',
          {rules: [{ required: true, message: '请输入密码' }]}
        ]"
              placeholder="密码"
              type="password"
            />
          </a-form-item>
          <a-form-item
            :wrapper-col="{offset:2}"
          >
       
          </a-form-item>
          <a-form-item
            class="login"
          >
            <a-button
              type="primary"
              html-type="submit"
              class="submitBtn"
            >
              登录
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from '../../api/auth'
import _ from 'lodash'
import storage from 'good-storage'

export default {
  name: 'login',
  data () {
    return {
   
      loginInfo: storage.get('loginInfo'),
      form: this.$form.createForm(this)
    }
  },
  created () {
   
  },
  mounted () {
   
  },
  methods: {
   
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields(
        (err, val) => {
          if (!err) {
            val.pid=9
            login(val).then((res) => { // 登陆成功相应的改动才能生效
              const { customers, usertoken, uid } = res
              const { username } = val
             
              // 用户信息
               if(customers.length>0){
              this.$store.commit('change_loginUser', {
                name: username,
                utoken: usertoken,
                uid: uid,
                clientList: customers,
              })
             
              this.$router.push('/searchWords')
              }
              else
              {
                this.$message.error('您没有账户权限，无法登录')
              }
            })
          }
        }
      )
    }
  }
}
</script>

<style lang='less'>
  @import '../../styles/common/variables';
  @import '../../styles/common/mixins';

  #login-wrap {
    min-width: 650px;
    .backgroundImg('../../assets/bg-login.png');
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    .login-box {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 450px;
      height: 515px;
      background-color: @w;
      box-shadow: 0 0 1px 1px @dc;
      .step-1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        .logo {
          margin-top: 75px;
        }
        .name {
          font-size: 24px;
          color: @tc;
          font-family: @pm;
          margin-top: 16px;
        }
        .ant-form {
          width: 100%;
          .username, .password {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 14px;
            .ant-form-explain {
              margin-top: 5px;
              margin-bottom: -15px;
            }
            .ant-input {
              padding-left: 0;
              width: 370px;
              height: 50px;
              line-height: 50px;
              display: flex;
              border: none;
              border-bottom: 1px solid @d9;
              &:focus {
                box-shadow: none;
                background-color: @w;
              }
            }
          }
          .login {
            position: absolute;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            bottom: 55px;
            .submitBtn {
              border-color: @30;
              &:after {
                border-color: @30;
              }
            }
            .ant-btn {
              width: 370px;
              height: 40px;
              background-color: @30;
            }
          }
          .forget {
            color: @link;
            padding-left: 110px;
            cursor: pointer;
          }
        }
        .register {
          .center();
          position: absolute;
          bottom: 0;
          height: 55px;
          width: 100%;
          background-color: @f567;
          font-size: 14px;
          color: @link;
          font-family: @pr;
          cursor: pointer;
        }
      }
    }
  }
</style>
